import React from "react";
import styled from "styled-components";
import Partner from "./Partner";
import ContentContainer from "../Core/ContentContainer";
import Fade from "react-reveal/Fade";
import Title from "../Core/Title";
import { Col, Row } from "styled-bootstrap-grid";

const PartnerPosition = styled.div`
  margin-top: 56px;
  padding: 24px 0 24px 0;
`;

const Partners = ({ introHeader, partnersElements }) => {

  return (
    <ContentContainer>
      <Row>
        <Col md={10} mdOffset={1}>
          {introHeader.header &&
          <Fade duration={500} delay={100}>
            <Title position={introHeader.header.position}>
              {introHeader.header}
            </Title>
          </Fade>
          }
          <PartnerPosition>
            {partnersElements.map((partner, index) => {
              return (
                <Partner key={index} partner={partner} index={index}/>
              )
            })}
          </PartnerPosition>
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default Partners