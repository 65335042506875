import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Core/Seo";
import Hero from "../components/Home/Hero";
import ClientsCarousel from "../components/Home/ClientsCarousel";
import TextImageBlock from "../components/Home/TextImageBlock";
import TextBlock from "../components/Home/TextBlock";
import AboutUs from "../components/Home/AboutUs";
import Projects from "../components/Home/Projects";
import Testimonials from "../components/Home/Testimonials";
import Technologies from "../components/Home/Technologies";
import Partners from "../components/Home/Parters";

const IndexPage = ({data}) => {
  const page = data.page;
  let pageContent = [];
  const blocks = page.blocks;

  blocks && blocks.forEach((block, index) => {
    const blockType = block.__typename

    if (blockType === "ContentfulHero") {
      pageContent.push(<Hero key={index} {...block} />)
    }

    if (blockType === "ContentfulClientsCarousel") {
      pageContent.push(<ClientsCarousel key={index} {...block}/>)
    }

    if (blockType === "ContentfulTextImageBlock") {
      pageContent.push(<TextImageBlock key={index} {...block}/>)
    }

    if (blockType === "ContentfulTextBlock") {
      pageContent.push(<TextBlock key={index} {...block}/>)
    }

    if (blockType === "ContentfulAboutUs") {
      pageContent.push(<AboutUs key={index} {...block}/>)
    }

    if (blockType === "ContentfulProjects") {
        pageContent.push(<Projects key={index} {...block}/>)
    }

    if (blockType === "ContentfulTechnologies") {
      pageContent.push(<Technologies key={index} {...block}/>)
    }

    if (blockType === "ContentfulTestimonials") {
      pageContent.push(<Testimonials key={index} {...block}/>)
    }

    if (blockType === "ContentfulPartners") {
      pageContent.push(<Partners key={index} {...block}/>)
    }
  })
  return (
    <Layout>
      <Seo title={page.title} metaTitle={page.metaTitle} metaDescription={page.metaDescription} path={'/'}/>
      {pageContent}
    </Layout>
  )
}

export default IndexPage;

export const pageQuery = graphql`
    query IndexQuery {
        page: contentfulHomepage {
            title
            description
            metaTitle
            metaDescription
            blocks {
                __typename
                ... on Node {
                    ... on ContentfulHero {
                        title {
                            raw
                        }
                        backgrounds {
                          backgroundDesktopDark {
                            title
                            file {
                              url
                            }
                          }
                          backgroundDesktopLight {
                            title
                            file {
                              url
                            }
                          }
                          backgroundMobileDark{
                            title
                            file {
                              url
                            }
                          }
                          backgroundMobileLight {
                            title
                            file {
                              url
                            }
                          }
                        }
                    }
                    ... on ContentfulClientsCarousel {
                        clients {
                            file {
                                url
                            }
                            title
                        }
                    }
                    ... on ContentfulTextImageBlock {
                        introHeader {
                            header
                            subHeader
                            position
                        }
                        text {
                            raw
                        }
                        cta {
                          ctaUrl
                          ctaLabel
                        }
                        image {
                             fluid(
                                maxWidth: 1920
                                quality: 100
                            ) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                            title
                        }
                        mobileImage {
                         fluid(
                                maxWidth: 1920
                                quality: 100
                            ) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                            title
                        }
                    }
                    ...on ContentfulTextBlock {
                        content {
                            raw
                        }
                        backgrounds {
                          backgroundDesktopDark {
                            title
                            file {
                              url
                            }
                          }
                          backgroundDesktopLight {
                            title
                            file {
                              url
                            }
                          }
                          backgroundMobileDark{
                            title
                            file {
                              url
                            }
                          }
                          backgroundMobileLight {
                            title
                            file {
                              url
                            }
                          }
                        }
                    }
                    ...on ContentfulAboutUs {
                        introHeader {
                            header
                            subHeader
                            position
                        }
                        textBlocks {
                            content {
                                raw
                            }
                        }
                        images {
                            fluid(
                                maxWidth: 1920
                                quality: 100
                            ) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                            title
                        }
                    }
                    ...on ContentfulProjects {
                        header {
                            header
                            subHeader
                            position
                        }
                        projects {
                            projectName
                            projectDescription {
                                raw
                            }
                            projectImage {
                                fluid(
                                    maxWidth: 1920
                                    quality: 100
                                ) {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                                title
                            }
                        }
                        backgrounds {
                          backgroundDesktopDark {
                            title
                            file {
                              url
                            }
                          }
                          backgroundMobileDark{
                            title
                            file {
                              url
                            }
                          }
                        }
                    }
                    ...on ContentfulTestimonials {
                        header {
                            header
                            subHeader
                            position
                        }
                        subtext {
                            raw
                        }
                        links {
                            ctaUrl
                            ctaLabel
                        }
                        testimonials {
                            author
                            position
                            content {
                                raw
                            }
                            image {
                                fluid(
                                    maxWidth: 240
                                    quality: 100
                                ) {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                                title
                            }
                        }
                    }
                    ... on ContentfulPartners {
                      introHeader {
                        header
                        position
                      }
                      partnersElements {
                        title
                        copy {
                          raw
                        }
                        link {
                          ctaLabel
                          ctaUrl
                        }
                        image {
                          title
                          fluid(
                            maxWidth: 500
                            quality: 100
                          ) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                          }
                        }
                        background {
                          title
                          fluid(
                            maxWidth: 900
                            quality: 100
                          ) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                    ...on ContentfulTechnologies {
                        header {
                            position
                            header
                        }
                        technologies {
                            name
                            technology {
                                name
                                darkIcon {
                                    file {
                                        url
                                    }
                                    title
                                }
                                lightIcon {
                                    file {
                                        url
                                    }
                                    title
                                }
                            }
                        }
                        backgrounds {
                          backgroundDesktopDark {
                            title
                            file {
                              url
                            }
                          }
                          backgroundDesktopLight {
                            title
                            file {
                              url
                            }
                          }
                          backgroundMobileDark{
                            title
                            file {
                              url
                            }
                          }
                          backgroundMobileLight {
                            title
                            file {
                              url
                            }
                          }
                        }
                    }
                }
            }
        }
    }
`
