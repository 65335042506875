import React, { useContext, useRef, useState } from "react"
import styled from "styled-components";
import ContentContainer from "../Core/ContentContainer";
import breakpoints from "../../styles/breakpoints";
import Title from "../Core/Title";
import { Col, Row } from "styled-bootstrap-grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import Slider from "react-slick";
import Quote from "../../resources/images/ui/quote.svg";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import Button from "../Core/Button";
import ArrowRightDark from "../../resources/images/ui/arrow-right-dark.svg";
import ArrowRightWhite from "../../resources/images/ui/arrow-white-right.svg";
import ArrowLeftDark from "../../resources/images/ui/arrow-left-dark.svg";
import ArrowLeftWhite from "../../resources/images/ui/arrow-white-left.svg";
import BackgroundImageLight from "../../resources/images/homepage/background-1.svg";
import BackgroundImageDark from "../../resources/images/homepage/background-3.svg";
import ThemeContext from "../../services/themes/themeContext"
import Fade from "react-reveal/Fade"


const TestimonialsContainer = styled.div`
  padding: 124px 0;
  position: relative;
  overflow: hidden;
  
  @media(min-width: ${breakpoints.lg}){
    padding: 224px 0 120px;
  }
`;

const TestimonialsContent = styled.div`
  margin-top: 64px; 
`;

const Image = styled(Img)`
  height: 71px;
  width: 71px;
  border-radius: 50%;
  margin: 0 auto;

  @media(min-width: ${breakpoints.md}){
    height: 100px;
    width: 100px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    height: 120px;
    width: 120px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.576px;
  ${fonts.RalewayRegular};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  margin-top: 22px;

  p {
    &:before {
      content: "";
      background: url(${Quote}) no-repeat;
      background-size: 26px 24px;
      display: inline-block;
      height: 24px;
      width: 26px;
      margin-right: 6px;
    }
  }
  
  @media(min-width: ${breakpoints.lg}){
    max-width: 570px;
    margin: 56px auto 0;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.96px;
    
    p {
      &:before {
        background-size: 38px 36px;
        width: 38px;
        height: 36px;
        margin-right: 8px;
      }
    }
  }
  @media(min-width: ${breakpoints.xl}){
    font-size: 22px;
  }

  @media(min-width: ${breakpoints.xxl}){
    font-size: 24px;
  }
`;

const Author = styled.div`
  font-size: 18px;
  line-height: 21px;
  ${fonts.RalewayRegular};
  color: ${colors.red};
  margin-top: 26px;
  margin-bottom: 8px;
  text-align: center;
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 32px;
    line-height: 37px;
    margin-top: 44px;
    margin-bottom: 4px;
  }
`;

const Position = styled.div`
  font-size: 12px;
  line-height: 14px;
  ${fonts.RalewayRegular};
  color: ${colors.red};
  text-align: center;
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 18px;
    line-height: 21px;
  }
`;

const IndicatorWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media(min-width: ${breakpoints.md}){
    margin-bottom: 80px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    margin-top: 40px;
  }
`;

const IndicatorLine = styled.div`
  height: 2px;
  width: 20px;
  border-radius: 1px;
  margin: 0 5px;
  background: ${props => props.active ? colors.red : props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  cursor: pointer;
  
  @media(min-width: ${breakpoints.lg}){
    width: 40px;
    height: 4px;
    margin: 0 8px;
    border-radius: 4px;
  }
`;

const Subtext = styled.div`
  ${fonts.RalewayRegular};
  font-size: 20px;
  line-height: 26px;
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  margin-bottom: 32px;

  @media(min-width: ${breakpoints.md}){
    font-size: 22px;
    line-height: 40px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 26px;
    line-height: 48px;
    letter-spacing: 1.6px;
    margin-bottom: 100px;
  }
  
  @media(min-width: ${breakpoints.xxl}){
    font-size: 32px;
    line-height: 52px;
    letter-spacing: 1.4px;
  }

  @media(min-width: 2560px){
    font-size: 36px;
    line-height: 60px;
    letter-spacing: 1.92px;
  }
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 40px;
    align-items: flex-start;
  }

  @media(min-width: ${breakpoints.lg}){
    margin-top: 130px;
  }

  @media(min-width: 1101px){
    margin-top: 85px;
  }

  @media(min-width: ${breakpoints.xxl}){
    margin-top: 76px;
  }
`;

const Links = styled.div`
  z-index: 10;
  
  a:first-of-type {
    margin-bottom: 16px;
  }
  
  @media(min-width: ${breakpoints.md}){
    a:first-of-type {
      margin-bottom: 32px;
    }
  }
`;

const SliderStyled = styled(Slider)`
  cursor: grab;
  
  @media(min-width: ${breakpoints.md}){
    padding: 0 24px;
    
    .slick-arrow:before {
      content: "";
    }

    .slick-prev {
      background: url(${props => props.theme.isLight ? ArrowLeftDark : ArrowLeftWhite}) no-repeat center center;
      background-size: 11px 24px;
      height: 24px;
      width: 11px;
      left: 0;

      @media(min-width: ${breakpoints.lg}){
        background-size: 11px 48px;
        height: 48px;
        width: 11px;
      }
    }
    
    .slick-next {
      background: url(${props => props.theme.isLight ? ArrowRightDark : ArrowRightWhite}) no-repeat center center;
      background-size: 11px 24px;
      height: 24px;
      width: 11px;
      right: 0;
      
      @media(min-width: ${breakpoints.lg}){
        background-size: 11px 48px;
        height: 48px;
        width: 11px;
      }
    }
  }

  @media(min-width: ${breakpoints.lg}){
    padding: 0 50px;
  }
`;

const Background = styled.img`
  height: auto;
  width: 40vw;
  min-height: 260px;
  max-height: 260px;
  min-width: 240px;
  position: absolute;
  right: -100px;
  bottom: -80px;
  transform: rotate(-8deg);
  
  @media(min-width: ${breakpoints.md}){
    bottom: 50px;
    max-height: 500px;
    max-width: 440px;
  }
  
  @media(min-width: ${breakpoints.xl}){
    right: 0;
  }
`;

const Testimonials = ({ header, testimonials, subtext, links }) => {
  const { isLight } = useContext(ThemeContext);
  const [slideIndex, setSlideIndex] = useState(0);
  let sliderRef = useRef(null);

  const changeSlide = (slideIndex) => {
    sliderRef.slickGoTo(slideIndex)
    setSlideIndex(slideIndex)
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    fade: true,
    cssEase: 'ease-in-out',
    beforeChange: (current, next) => setSlideIndex(next),

    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        }
      }
    ]
  }

  return (
    <TestimonialsContainer>
      <ContentContainer>
        {header.header &&
          <Fade duration={500} delay={100}>
            <Title position={header.position}>
              {header.header}
            </Title>
          </Fade>
        }
        <Fade>
          <TestimonialsContent>
            <Row>
              <Col xs={10} xsOffset={1} sm={10} smOffset={1} md={6} mdOffset={0}>
                <SliderStyled {...settings} ref={slider => (sliderRef = slider)}>
                  {testimonials.map((testimonial, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Image fluid={testimonial.image.fluid} alt={testimonial.image.title} />
                        <Content>
                          {documentToReactComponents(JSON.parse(testimonial.content.raw))}
                        </Content>
                        <Author>{testimonial.author}</Author>
                        <Position>{testimonial.position}</Position>
                      </React.Fragment>
                    )
                  })}
                </SliderStyled>
                {testimonials.length > 2 &&
                  <IndicatorWrapper>
                    {testimonials.map((line, index) => {
                      const active = slideIndex === index
                      return (
                        <IndicatorLine key={index} onClick={() => changeSlide(index)} active={active} />
                      )
                    })}
                  </IndicatorWrapper>
                }
              </Col>
              <Col xs={10} xsOffset={1} sm={10} smOffset={1} md={5} mdOffset={1}>
                <BlockContent>
                  {subtext && <Subtext>{documentToReactComponents(JSON.parse(subtext.raw))}</Subtext>}
                  {links &&
                    <Links>
                      {links.map((link, index) => {
                        return (
                          <Button key={index} to={link.ctaUrl} large={1}>
                            {link.ctaLabel}
                          </Button>
                        )
                      })}
                    </Links>
                  }
                  <Background src={isLight ? BackgroundImageLight : BackgroundImageDark} alt="Background" />
                </BlockContent>
              </Col>
            </Row>
          </TestimonialsContent>
        </Fade>
      </ContentContainer>
    </TestimonialsContainer>
  )
}

export default Testimonials;
