import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import Button from "../Core/Button";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";

const Display = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.darkGrey};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1),0 0 18px rgba(0, 0, 0, 0.06);

    @media(min-width: ${breakpoints.md}){
    grid-template-columns: auto 1fr;
  }

  @media(min-width: ${breakpoints.lg}){
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1), 0 0 38px rgba(0, 0, 0, 0.06);
  }
`;

const TextPosition = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 16px 16px 16px;

  @media(min-width: ${breakpoints.md}){
    padding: 24px 24px 24px 40px;
  }
`;

const TitleText = styled.h4`
  font-size: 24px;
  padding-bottom: 26px;
  letter-spacing: 0.64px;
  ${fonts.RalewayRegular};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  
  @media(min-width: ${breakpoints.md}){
    font-size: 26px;
    line-height: 26px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 1.4px;
  }
  
  @media(min-width: ${breakpoints.xxl}){
    font-size: 38px;
    line-height: 38px;
    letter-spacing: 1.4px;
  }

  @media(min-width: 2560px){
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 1.92px;
  }
`;

const TextBlock = styled.div`
  font-size: 16px;
  padding-bottom:40px;
  letter-spacing: 1px;
  line-height: 28px;
  ${fonts.RalewayRegular};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};

  @media(min-width: ${breakpoints.md}){
    font-size: 18px;
    line-height: 32px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 19px;
    line-height: 34px;
    letter-spacing: 1.2px;
  }
  
  @media(min-width: ${breakpoints.xxl}){
    font-size: 21px;
    line-height: 38px;
    letter-spacing: 1.4px;
  }
`;

const ImagesPosition = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const Logo = styled(Img)`
  position: absolute !important;
  margin: 0;
  height: ${props => props.secondImage ? "160px" : "131px"};
  width: ${props => props.secondImage ? "160px" : "131px"};

  @media(min-width: ${breakpoints.lg}){
    height: ${props => props.secondImage ? "250px" : "170px"};
    width: ${props => props.secondImage ? "250px" : "170px"};
  }

  @media(min-width: ${breakpoints.xl}){
    height: ${props => props.secondImage ? "315px" : "231px"};
    width: ${props => props.secondImage ? "315px" : "231px"};
  }
`

const Background = styled(Img)`
  width: 100%;
  height: 224px;
  margin:0;
  padding:0;

  @media(min-width: ${breakpoints.md}){
    width: 300px;
    height: 100%;
  }

  @media(min-width: ${breakpoints.lg}){
    width: 375px;
    height: 100%;
  }

  @media(min-width: ${breakpoints.xl}){
    width: 450px;
    height: 100%;
  }
`;

const Partner = ({ partner, index }) => {
  const secondImage = index === 1;
  return (
    <Display>
      <ImagesPosition>
        <Background fluid={partner.background.fluid} alt={partner.background.title} />
        <Logo fluid={partner.image.fluid} alt={partner.image.title} secondImage={secondImage}/>
      </ImagesPosition>
      <TextPosition>
        <TitleText>
          {partner.title}
        </TitleText>
        <TextBlock>{documentToReactComponents(JSON.parse(partner.copy.raw))}</TextBlock>
        <Button to={partner.link.ctaUrl}>{partner.link.ctaLabel}</Button>
      </TextPosition>
    </Display>
  )
}

export default Partner;