import React, { useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import ContentContainer from "../Core/ContentContainer";
import { Col, Row } from "styled-bootstrap-grid";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import Fade from 'react-reveal/Fade';

const Clients = styled.div`
  background: ${props => props.theme.isLight ? colors.grey : colors.mediumNavy};
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: grab;
`;

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .slick-slide {
    height: inherit;
    
    div {
      height: 100%;
    }
  }
`;

const SlideContent = styled.div`
  min-width: 140px;
  margin-left: 20px;
  margin-right: 20px;
  @media(min-width: ${breakpoints.md}){
    width: 240px;  
    margin-left: 0;
    margin-right: 0;
  }
`;

const Image = styled.img`
  margin: 20px auto 20px auto;
  max-height: 56px;
  -webkit-filter:invert(${props => props.theme.isLight ? 1:0});
  filter:invert(${props => props.theme.isLight ? 1:0});
  
  @media(min-width: ${breakpoints.md}){
    max-height: 64px;
    margin: 24px auto 24px auto;
  }
`;

const ClientsCarousel = ({ isLight, clients }) => {
  const slider = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    variableWidth: true
  }

  return (
    <Clients>
      <Fade duration={1500} delay={500} right>
        <ContentContainer>
          <Row>
            <Col md={12}>
              <StyledSlider {...settings} ref={slider}>
                {clients.map((client, index) => {
                  return (
                    <React.Fragment key={index}>
                      <SlideContent>
                        <Image src={client.file.url} alt={client.title} isLight={isLight}/>
                      </SlideContent>
                    </React.Fragment>
                  )
                })}
              </StyledSlider>
            </Col>
          </Row>
        </ContentContainer>
      </Fade>
    </Clients>
  )
}

export default ClientsCarousel;
