import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Title from "../Core/Title"
import ContentContainer from "../Core/ContentContainer";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Slider from "react-slick";
import { Col, Row } from "styled-bootstrap-grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import fonts from "../../styles/fonts";
import ShadowImage from "../../resources/images/homepage/shadow.png";
import BottomBackgroundImage from "../../resources/images/homepage/background-1.svg";
import Fade from "react-reveal/Fade"
import './Projects.scss';
import ThemeContext from "../../services/themes/themeContext";
import Arrows from "../../resources/images/ui/arrows.svg";

const ProjectsContainer = styled.div`
  background: ${colors.red};
  position: relative;
`;

const ShadowTop = styled.div`
  height: 76px;
  
  @media(min-width: ${breakpoints.md}){
    height: 146px;
  }
`;

const ShadowBottom = styled.div`
  height: 76px;
  
  @media(min-width: ${breakpoints.md}){
    height: 146px;
  }
`;

const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 34px;
  
  @media(min-width: ${breakpoints.lg}){
    margin-top: 60px;
  }
`;

const IndicatorLine = styled.div`
  height: 2px;
  width: 20px;
  border-radius: 1px;
  margin: 0 5px;
  cursor: pointer;
  background: ${props => props.active ? colors.darkGrey : colors.lightGrey};
  
  @media(min-width: ${breakpoints.md}){
    height: 4px;
    width: 40px;
    margin: 0 8px;
    border-radius: 4px;
  }
`;

const CarouselContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 90px;
  position: relative;
  z-index: 2;
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 60px;
    margin-bottom: 60px;
  }
`;

const SliderStyled = styled(Slider)`
  margin-top: 64px;
  cursor: grab;
  z-index: 2;

  .slick-track {
    display: flex !important;
  }
  
  .slick-slide {
    height: inherit !important;
    display: flex;
  }
  
  @media(min-width: ${breakpoints.lg}){
    margin-top: 100px;
  }
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Name = styled.div`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.672px;
  ${fonts.CabinBold};
  color: ${colors.lightGrey};
  margin-bottom: 24px;

  @media(min-width: ${breakpoints.md}){
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 25px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 30px;
    line-height: 48px;
    letter-spacing: 1.4px;
  }
  
  @media(min-width: ${breakpoints.xxl}){
    font-size: 38px;
    line-height: 52px;
    letter-spacing: 1.4px;
  }

  @media(min-width: 2560px){
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 1.92px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.56px;
  ${fonts.RalewayRegular};
  color: ${colors.lightGrey};
  margin-bottom: 24px;
  
  a {
    color: ${colors.lightGrey};
    ${fonts.CabinRegular};
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    margin-top: 24px;
    display: block;
    
    &:hover {
      text-decoration: underline;
    }
    
    ::after {
      content: "";
      background: url(${Arrows}) no-repeat;
      background-size: 16px 10px;
      height: 11px;
      width: 16px;
      margin-left: 8px;
      display: inline-block;
    }
  }
  
  @media(min-width: ${breakpoints.md}){
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 25px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 24px;
    line-height: 48px;
    letter-spacing: 1.4px;
    
    a {
      font-size: 16px;
    }
  }
  
  @media(min-width: ${breakpoints.xxl}){
    font-size: 30px;
    line-height: 52px;
    letter-spacing: 1.4px;
  }

  @media(min-width: 2560px){
    font-size: 36px;
    line-height: 60px;
    letter-spacing: 1.92px;
  }

`;

const ContentBlock = styled.div`
  @media(min-width: ${breakpoints.md}){
    margin: 0 20px;
  }
`;

const ImageBlock = styled.div`
  @media(min-width: ${breakpoints.md}){
    margin: 0 20px;
  }
`;

const ImgStyled = styled(Img)`
  z-index: 2;
  
  @media(min-width: ${breakpoints.md}){
    max-height: 354px;
    max-width: 524px;
  }
`;

const ShadowBackground = styled.img`
  width: 100%;
`;

const BottomImage = styled.img`
  width: 40%;
  height: auto;
  display: block;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: -20px;
  
  @media(min-width: ${breakpoints.md}){
    max-height: 430px;
    max-width: 380px;
    height: 100%;
    width: 100%;
    left: -40px;
  }
`;

const RowStyled = styled(Row)`
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  
  @media(min-width: ${breakpoints.md}){
    flex-direction: row;
    justify-content: unset;
    height: unset;
  }
`;

const BackgroundMobile = styled.img`
  display: block;
  position: absolute;
  top: 56px;
  right: 0;
  height: 200px;

  @media(min-width: ${breakpoints.xs}){
    height: 300px;
  }

  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const BackgroundDesktop = styled.img`
  display: none;

  @media(min-width: ${breakpoints.md}) {
    display: block;
    position: absolute;
    top: 56px;
    right: 0;
    height: 300px;
  }

  @media(min-width: ${breakpoints.lg}){
    height: 500px;
  }

  @media(min-width: ${breakpoints.xl}){
    height: 625px;
  }
`;

const Projects = ({ header, projects, backgrounds }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const {isLight} = useContext(ThemeContext);
  let sliderRef = useRef(null);
  const { backgroundDesktopDark, backgroundMobileDark } = backgrounds;

  const changeSlide = (slideIndex) => {
    sliderRef.slickGoTo(slideIndex)
    setSlideIndex(slideIndex)
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  return (
    <ProjectsContainer id="projects">
      <ShadowTop className={`cubic-bezier-${isLight ? 'light': 'dark'}-top`}/>
      <BackgroundMobile src={backgroundMobileDark.file.url} alt={backgroundMobileDark.title}/>
      <BackgroundDesktop src={backgroundDesktopDark.file.url} alt={backgroundDesktopDark.title}/>
      <Fade>
        <CarouselContainer>
          <ContentContainer>
            {header.header &&
            <Fade duration={500} delay={100}>
              <Title position={header.position} isBlack={false}>
                {header.header}
              </Title>
            </Fade>
            }
            <Row>
              <Col xs={10} xsOffset={1} sm={10} smOffset={1}>
                <SliderStyled {...settings} ref={slider => (sliderRef = slider)}>
                  {projects.map((project, index) => {
                    return (
                      <Slide key={index}>
                        <RowStyled>
                          <Col md={6} mdOrder={2}>
                            <ContentBlock>
                              <Name>{project.projectName}</Name>
                              <Description>{documentToReactComponents(JSON.parse(project.projectDescription.raw))}</Description>
                            </ContentBlock>
                          </Col>
                          <Col md={6} mdOrder={1}>
                            <ImageBlock>
                              <ImgStyled fluid={project.projectImage.fluid} alt={project.projectImage.title} />
                              <ShadowBackground src={ShadowImage} alt={""} />
                            </ImageBlock>
                          </Col>
                        </RowStyled>
                      </Slide>
                    )
                  })}
                </SliderStyled>
              </Col>
            </Row>

            {projects.length > 1 &&
            <IndicatorWrapper>
              {projects.map((line, index) => {
                const active = slideIndex === index
                return (
                  <IndicatorLine key={index} onClick={() => changeSlide(index)} active={active} />
                )
              })}
            </IndicatorWrapper>
            }
            <BottomImage src={BottomBackgroundImage} alt={"Background image"} />
          </ContentContainer>
        </CarouselContainer>
      </Fade>
      <ShadowBottom className={`cubic-bezier-${isLight ? 'light': 'dark'}-bottom`}/>
    </ProjectsContainer>
  )
}

export default Projects;
