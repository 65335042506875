import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Title from "../Core/Title";
import ContentContainer from "../Core/ContentContainer";
import Slider from "react-slick";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import ThemeContext from "../../services/themes/themeContext";
import breakpoints from "../../styles/breakpoints";
import { Col, Row } from "styled-bootstrap-grid";
import Fade from "react-reveal/Fade";

const TechnologiesContainer = styled.div`
  margin: 80px 0 140px;
  position: relative;
  
  @media(min-width: ${breakpoints.lg}){
    margin: 120px 0 240px;
  }
`;

const TechnologiesCarousel = styled.div`
  margin-top: 66px;
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 100px;
  }
`;

const SliderStyled = styled(Slider)`
  z-index: 2;
  cursor: grab;
  margin-top: 35px;
`;

const TechnologyIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  cursor: default;
`;

const TechnologyBlock = styled.div`
  display: block;
  flex-direction: column;
  justify-content: center;
  margin: 4px 3px 4px 3px;
  padding: 19px;
  @media(min-width: ${breakpoints.lg}){
    margin: 10px;
    padding: 30px 35px;
  }
  
  :hover {
    background: ${props => props.theme.isLight ? '#ebdce0' : '#3f1929'};
    border-radius: 5px;
  }
`;

const TechnologyIcon = styled.img`
  height: 40px;
  max-width: 80px;
  margin: auto;

  @media(min-width: ${breakpoints.md}){
    height: 48px;
    max-width: 100px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    height: 64px;
    max-width: 130px;
  }
`;

const Language = styled.div`
  font-size: 14px;
  line-height: 16px;
  ${fonts.RalewayMedium};
  text-align: center;
  margin-top: 10px;
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 18px;
    line-height: 21px;
    margin-top: 16px;
  }
`;

const Border = styled.div`
  height: 2px;
  width: 100%;
  margin-top: -2px;
  background: ${colors.lilac};
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: scroll;
  
  @media(min-width: ${breakpoints.xs}){
    justify-content: center;
  }

  @media(min-width: ${breakpoints.md}){
    justify-content: flex-start;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const TechnologyName = styled.div`
  font-size: 16px;
  line-height: 19px;
  ${fonts.CabinRegular};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  p {
    padding-bottom: 8px;
    margin: 0 18px;
  }

  @media(min-width: ${breakpoints.md}){
    font-size: 24px;
    line-height: 30px;
    width: 100%;
    
    p {
      padding-bottom: 14px;
    }
  }
  
  @media(min-width: ${breakpoints.lg}){
    margin: 0;
  }
`;

const Line = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  background: ${props => props.active ? colors.darkRed : colors.lilac};
`;

const Slide = styled.div`
  height: ${props => props.active ? 'auto': '0'};
  overflow: hidden;
`;

const BackgroundMobile = styled.img`
  display: block;
  position: absolute;
  width: 100vw;
  height: auto;
  bottom: -280px;
  
  @media(min-width: ${breakpoints.xxs}){
    bottom: -300px;
  }

  @media(min-width: ${breakpoints.xs}){
    bottom: -350px;
    max-width: 500px;
  }
  
  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const BackgroundDesktop = styled.img`
  display: none;

  @media(min-width: ${breakpoints.md}) {
    display: block;
    position: absolute;
    height: 400px;
    width: 80vw;
    bottom: -350px;
  }
  
  @media(min-width:${breakpoints.lg}){
    background-size: cover;
    height: 600px;
    width: 80vw;
    bottom: -550px;
  }

  @media(min-width:${breakpoints.xxl}){
    background-size: cover;
    height: 800px;
    width: 1157px;
    bottom: -700px;
  }
`;

const Technologies = ({ header, technologies, backgrounds }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  let sliderRef = useRef(null);
  const {isLight} = useContext(ThemeContext);
  const { backgroundDesktopDark, backgroundDesktopLight, backgroundMobileDark, backgroundMobileLight } = backgrounds;

  const changeSlide = (slideIndex) => {
    sliderRef.slickGoTo(slideIndex)
    setSlideIndex(slideIndex)
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    swipe: true,
    fade: true,
    swipeToSlide: false,
    draggable: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    beforeChange: (current, next) => setSlideIndex(next)
  }

  return (
    <TechnologiesContainer>
      <ContentContainer>
        {header.header &&
          <Fade duration={500} delay={100}>
            <Title position={header.position}>
              {header.header}
            </Title>
          </Fade>
        }
        <Fade>
          <TechnologiesCarousel>
            <Row>
              <Col lg={8} lgOffset={2}>
                <Navigation>
                  {technologies.map((technology, index) => {
                    const active = slideIndex === index

                    return (
                      <TechnologyName key={index} onClick={() => changeSlide(index)} active={active}>
                        <p>{technology.name}</p>
                        <Line active={active}/>
                      </TechnologyName>
                    )
                  })}
                </Navigation>
              </Col>
            </Row>
            <Border/>

            <SliderStyled {...settings} ref={slider => (sliderRef = slider)}>
              {technologies.map((element, index) => {
                return (
                  <Slide key={index} active={slideIndex === index}>
                    {element.technology !== null &&
                    <TechnologyIcons>
                      {element.technology.map((item, index) => {
                        return (
                          <TechnologyBlock key={index}>
                            <TechnologyIcon
                              src={isLight ? item.darkIcon.file.url : item.lightIcon.file.url}
                              alt={isLight ? item.darkIcon.title : item.lightIcon.title}/>
                            <Language>{item.name}</Language>
                          </TechnologyBlock>
                        )
                      })}
                    </TechnologyIcons>
                    }
                  </Slide>
                )
              })}
            </SliderStyled>
          </TechnologiesCarousel>
        </Fade>
      </ContentContainer>
      <BackgroundMobile src={isLight ? backgroundMobileLight.file.url : backgroundMobileDark.file.url} alt={isLight ? backgroundMobileLight.title : backgroundMobileDark.title}/>
      <BackgroundDesktop src={isLight ? backgroundDesktopLight.file.url : backgroundDesktopDark.file.url} alt={isLight ? backgroundDesktopLight.title : backgroundDesktopDark.title}/>
    </TechnologiesContainer>
  )
}

export default Technologies;
