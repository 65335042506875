import React, { useContext } from "react";
import ContentContainer from "../Core/ContentContainer";
import { Col, Row } from "styled-bootstrap-grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Fade from "react-reveal/Fade";
import ThemeContext from "../../services/themes/themeContext";

const TextBlockContainer = styled.div`
  margin: 26px auto 30vh;
  position: relative;
  z-index: 3;
  
  @media(min-width: ${breakpoints.md}){
    margin: 68px auto 220px;
  }

  @media(min-width: ${breakpoints.lg}){
    margin: 100px auto 250px;
  }

  @media(min-width: ${breakpoints.xl}){
    margin: 140px auto 290px;
  }

  @media(min-width: ${breakpoints.xxl}){
    margin: 140px auto 320px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.576px;
  ${fonts.RalewayRegular};
  z-index: 2;
  position: relative;
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  
  p {
    margin-bottom: 20px;
  }
  
  p:last-of-type {
    margin-bottom: 0;
  }
  
  @media(min-width: ${breakpoints.md}){
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.96px;
    text-align: center;
    
    p {
      margin-bottom: 26px;
    }
  }

  @media(min-width: ${breakpoints.lg}){
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1.2px;
  }
  
  @media(min-width: ${breakpoints.xxl}){
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.4px;
  }
`;

const BackgroundMobile = styled.img`
   display: block;
   position: absolute;
   width: 100vw;
   min-height: 300px;
   left: 0;
   bottom: -24vh;
  
  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const BackgroundDesktop = styled.img`
  display: none;
  
  @media(min-width: ${breakpoints.md}) {
    display: block;
    position: absolute;
    height: 810px;
    max-width: 1200px;
    bottom: -430px;
    width: 75vw;
    left: unset;
    right: 0;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    bottom: -420px;
  }
`;

const TextBlock = ({ content, backgrounds }) => {
  const { isLight } = useContext(ThemeContext);
  const { backgroundDesktopDark, backgroundDesktopLight, backgroundMobileDark, backgroundMobileLight } = backgrounds;

  return (
    <TextBlockContainer>
      <ContentContainer>
        <Row>
          <Col md={10} mdOffset={1}>
            <Fade duration={500} delay={100}>
              <Text>
                {documentToReactComponents(JSON.parse(content.raw))}
              </Text>
            </Fade>
          </Col>
        </Row>
      </ContentContainer>
      <BackgroundMobile src={isLight ? backgroundMobileLight.file.url : backgroundMobileDark.file.url} alt={isLight ? backgroundMobileLight.title : backgroundMobileDark.title}/>
      <BackgroundDesktop src={isLight ? backgroundDesktopLight.file.url : backgroundDesktopDark.file.url} alt={isLight ? backgroundDesktopLight.title : backgroundDesktopDark.title}/>
    </TextBlockContainer>
  )
}

export default TextBlock;
