import React, { useRef, useState } from "react";
import Title from "../Core/Title";
import ContentContainer from "../Core/ContentContainer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import Img from "gatsby-image";
import breakpoints from "../../styles/breakpoints";
import Slider from "react-slick";
import { Col, Row } from "styled-bootstrap-grid";
import Fade from "react-reveal/Fade"

const AboutUsContainer = styled.div`
  z-index: 3;
  position: relative;
`;

const TextBlock = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.504px;
  ${fonts.RalewayThin};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.white};
  margin-top: 20px;
  padding-right: 36px;

  b {
    ${fonts.RalewayMedium};
    display: block;
    margin-bottom: 8px;
  }
  
  @media(min-width: ${breakpoints.md}){
    font-size: 16px;
    line-height: 26px;
    margin-top: 0;
    max-width: 450px;
    padding: 0 0 20px 24px;
 
    b {
      font-size: 18px;
    }
  }
`;

const ImagesBlock = styled.div`
  margin-top: 34px;
  margin-bottom: -20px;
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 75px;
    margin-bottom: 0;
  }
`;

const SliderStyled = styled(Slider)`
  padding-left: 24px;
  cursor: grab;
`;

const Desktop = styled.div`
  display: none;
  
  @media(min-width: ${breakpoints.md}){
    display: block;
    margin-bottom: 120px;
  }
`;

const Mobile = styled.div`
  display: block;
  
  @media(min-width: ${breakpoints.md}){
    display: none;
  }
`;

const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 22px;
`;

const IndicatorLine = styled.div`
  background: ${props => props.active ? colors.red : props.theme.isLight ? colors.darkGrey : colors.white};
  margin: 0 5px;
  height: 2px;
  width: 20px;
  border-radius: 1px;
  cursor: pointer;
`;

const Slide = styled.div`
  max-width: 68vw;
  
  @media(min-width: ${breakpoints.md}){
    display: flex;
    flex-direction: row;
  }
`;

const SliderLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media(min-width: ${breakpoints.md}){
    flex-direction: column;
  }
`;

const Circle = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: block;
  border: 2px solid ${props => props.first ? colors.red : props.second ? "#5D3156" : props.third ? "#453B63" : props.fourth ? "#34395E" : props.fifth && "#24375A"};
`;

const Line = styled.div`
  display: block;
  height: 2px;
  width: 100%;
  ${props => props.first && "background: linear-gradient(90deg, #A20029 0%, #5D3156 100%)"};
  ${props => props.second && "background: linear-gradient(90deg, #5D3156 0%, #453B63 100%)"};
  ${props => props.third && "background: linear-gradient(90deg, #453B63 0%, #34395E 100%)"};
  ${props => props.fourth && "background: linear-gradient(90deg, #34395E 0%, #24375A 100%)"};
  ${props => props.fifth && "background: linear-gradient(90deg, #24375A 0%, #A20029 100%)"};

  @media(min-width: ${breakpoints.md}){
    height: 100%;
    width: 2px;
    ${props => props.first && "background: linear-gradient(180deg, #A20029 0%, #5D3156 100%)"};
    ${props => props.second && "background: linear-gradient(180deg, #5D3156 0%, #453B63 100%)"};
    ${props => props.third && "background: linear-gradient(180deg, #453B63 0%, #34395E 100%)"};
    ${props => props.fourth && "background: linear-gradient(180deg, #34395E 0%, #24375A 100%)"};
    ${props => props.fifth && "background: transparent"};
  }
`;

const Margin = styled.div`
  display: block;
  margin-top: 40px;
`

const AboutUs = ({ introHeader, textBlocks, images }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  let sliderRef = useRef(null);

  const changeSlide = (slideIndex) => {
    sliderRef.slickGoTo(slideIndex)
    setSlideIndex(slideIndex)
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: textBlocks.length > 1,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    variableWidth: true,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  return (
    <AboutUsContainer id="about">
      <ContentContainer>
        {introHeader.header &&
          <Fade duration={500} delay={100}>
            <Title position={introHeader.position} sub={introHeader.subHeader}>
              {introHeader.header}
            </Title>
          </Fade>
        }
        <Mobile>
          <ImagesBlock>
            {images.map((image, index) => {
              return (
                <Img fluid={image.fluid} alt={image.title} key={index}/>
              )
            })}
          </ImagesBlock>
        </Mobile>
      </ContentContainer>

      <Mobile>
        <SliderStyled {...settings} ref={slider => (sliderRef = slider)}>
          {textBlocks.map((text, index) => {
            const active = slideIndex === index;
            const first = index === 0;
            const second = index === 1;
            const third = index === 2;
            const fourth = index === 3;
            const fifth = index === 4;
            return (
              <Slide key={index}>
                <SliderLine>
                  <Circle first={first} second={second} third={third} fourth={fourth} fifth={fifth} active={active}/>
                  <Line first={first} second={second} third={third} fourth={fourth} fifth={fifth}/>
                </SliderLine>
                <TextBlock>{documentToReactComponents(JSON.parse(text.content.raw))}</TextBlock>
              </Slide>
            )
          })}
        </SliderStyled>
        <ContentContainer>
          {textBlocks.length > 2 &&
            <IndicatorWrapper>
              {textBlocks.map((line, index) => {
                const active = slideIndex === index;
                return (
                  <IndicatorLine key={index} onClick={() => changeSlide(index)} active={active}/>
                )
              })}
            </IndicatorWrapper>
          }
        </ContentContainer>
      </Mobile>

      <Desktop>
        <Margin />
        <ContentContainer>
          <Row>
            <Col md={6} lg={5} lgOffset={1}>
              {textBlocks.map((text, index) => {
                const first = index === 0;
                const second = index === 1;
                const third = index === 2;
                const fourth = index === 3;
                const fifth = index === 4;
                return (
                  <Fade duration={600} key={index}>
                    <Slide>
                      <SliderLine>
                        <Circle first={first} second={second} third={third} fourth={fourth} fifth={fifth}/>
                        <Line first={first} second={second} third={third} fourth={fourth} fifth={fifth}/>
                      </SliderLine>
                      <TextBlock>{documentToReactComponents(JSON.parse(text.content.raw))}</TextBlock>
                    </Slide>
                  </Fade>
                )
              })}
            </Col>
            <Col md={6} lg={6}>
              <ImagesBlock>
                {images.map((image, index) => {
                  return (
                    <Img fluid={image.fluid} alt={image.title} key={index}/>
                  )
                })}
              </ImagesBlock>
            </Col>
          </Row>
        </ContentContainer>
      </Desktop>
    </AboutUsContainer>
  )
}

export default AboutUs;
