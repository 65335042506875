import React, { useContext } from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import ThemeContext from "../../services/themes/themeContext";

const TitleText = styled.h4`
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.64px;
  ${fonts.RalewayRegular};
  color: ${props => props.isBlack ? colors.darkGrey : colors.lightGrey};
  text-align: ${props => props.left ? "left" : "right"};
  
  @media(min-width: ${breakpoints.xxs}){
    font-size: 20px;
  }

  @media(min-width: ${breakpoints.md}){
    font-size: 27px;
    line-height: 37px;
  }

  @media(min-width: ${breakpoints.xl}){
    font-size: 32px;
  }
`;

const SmallTitle = styled.small`
  display: block;
  font-size: 14px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 18px;
  }
`

const Title = ({ children, position, isBlack, sub }) => {
  const {isLight} = useContext(ThemeContext);
  if (isBlack === undefined){
    isBlack = isLight;
  }

  return (
    <TitleText left={position} isBlack={isBlack}>
      {children}
      {sub &&
        <SmallTitle>{sub}</SmallTitle>
      }
    </TitleText>
  )
}

export default Title;
