import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import colors from "../../styles/colors";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Col, Row } from "styled-bootstrap-grid";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import Fade from 'react-reveal/Fade';
import LightSVG from "../../resources/images/homepage/light.svg";
import LightDarkSVG from "../../resources/images/homepage/light-dark.svg";
import ThemeContext from "../../services/themes/themeContext";
import ShadowSVG from "../../resources/images/homepage/light-shadow.svg";
import ShadowDarkSVG from "../../resources/images/homepage/dark-shadow.svg";

const HeroContainer = styled.div`
  overflow: hidden;
`;

const ContentContainer = styled.div`
  @media(min-width: ${breakpoints.sm}){
    max-width: 1392px;
    width: calc(100% - 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 228px);
  padding: 68px 24px 64px 24px;
  overflow: hidden;

  @media(min-width: ${breakpoints.sm}){
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 108px 0 64px 0;
    height: calc(100vh - 301px);
  }
  
  @media(min-width: ${breakpoints.md}){
    align-items: center;
    padding-top: 125px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    padding: 165px 0 80px;
    height: calc(100vh - 357px);
    min-height: 472px;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  max-width: 256px;
  text-align: center;
  margin: auto;
  z-index: 10;
  order: 6;
  ${fonts.CabinBold};
  text-transform: uppercase;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 0.5px;
  
  b {
    color: ${colors.red};
    ${fonts.Lagi};
  }

  @media(min-width: 350px){
    max-width: 281px;
    font-size: 44px;
    line-height: 44px;
  }

  @media(min-width: ${breakpoints.sm}){
    order: 1;
    font-size: 60px;
    line-height: 68px;
    max-width: 360px;
    text-align: left;
  }
  
  @media(min-width: ${breakpoints.md}){
    font-size: 60px;
    line-height: 68px;
    max-width: 375px;
  }

  @media(min-width: ${breakpoints.lg}){
    font-size: 80px;
    line-height: 88px;
    max-width: 500px;
    padding-top: 0;
  }

  @media(min-width: ${breakpoints.xl}){
    font-size: 94px;
    line-height: 92px;
  }

  @media(min-width: ${breakpoints.xxl}){
    font-size: 104px;
    line-height: 134px;
    max-width: 650px;
    padding-top: 0;
  }
  @media(min-width: 2560px){
    font-size: 120px;
    line-height: 165px;
  }
`;

const ImagePosition = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ImageBlock = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  order: 1;
  z-index: 4;
  padding-top: 40px;
  margin-bottom: -50px;
  
  @media(min-width: 350px){
    justify-content: center;
    padding-top: 40px;
  }

  @media(min-width: ${breakpoints.sm}){
    max-width: 40vw;
    margin: 0 0 0 auto;
    order: 2;
    position: absolute !important;
    right: 0;
    padding-top: 80px;
  }

  @media(min-width: ${breakpoints.md}){
    padding-top: 40px;
  }

  @media(min-width: ${breakpoints.lg}){
    right: unset;
    left: 54vw;
    max-width: 300px;
    width: 100%;
    padding-top: 0;
  }

  @media(min-width: ${breakpoints.xl}){
    justify-content: flex-start;
    left: 56vw;
  }

  @media(min-width: 1600px){
    left: 52vw;
  }

  @media(min-width: 1800px){
    left: 45vw;
  }

  @media(min-width: 2000px){
    left: 42vw;
  }

  @media(min-width: 2200px){
    left: 40vw;
  }

  @media(min-width: 2400px){
    left: 35vw;
  }

  @media(min-width: 2600px){
    left: 33vw;
  }

  @media(min-width: 2800px){
    left: 31vw;
  }

  @media(min-width: 3000px){
    left: 30vw;
  }

  @media(min-width: 3200px){
    left: 28vw;
  }

  @media(min-width: 3400px){
    left: 26vw;
  }
`;

const animationDark = keyframes`
  from {
    filter: drop-shadow(0px 0px 1px #B20234) drop-shadow(0px 0px 3px #B20234) drop-shadow(0px 0px 10px #870C32) drop-shadow(0px 0px 30px #830228) drop-shadow(0px 0px 50px #830228);
  }
  to {
    filter: drop-shadow(0px 0px 3px #B20234) drop-shadow(0px 0px 6px #B20234) drop-shadow(0px 0px 15px #870C32) drop-shadow(0px 0px 40px #830228) drop-shadow(0px 0px 60px #830228);
  }
`

const animationLight = keyframes`
  from {
    filter: drop-shadow(0px 0px 1px #C9003D) drop-shadow(0px 0px 3px #C9003D) drop-shadow(0px 0px 10px #a30031) drop-shadow(0px 0px 30px #830228) drop-shadow(0px 0px 50px #830228);
  }
  to {
    filter: drop-shadow(0px 0px 3px #C9003D) drop-shadow(0px 0px 6px #C9003D) drop-shadow(0px 0px 15px #a30031) drop-shadow(0px 0px 40px #830228) drop-shadow(0px 0px 60px #830228);
  }
`

const Light = styled.img`
  width: 100%;
  display: none;
  margin: auto;
  z-index: 10;
  opacity: 0.95;
  animation-name: ${props => props.theme.isLight ? animationLight : animationDark};
  animation-duration: 1.2s;
  animation-direction: alternate;
  animation-timing-function: steps(14, jump-end);
  animation-iteration-count: infinite;
  transform: translateZ(0);
  will-change: transform;
  backface-visibility: hidden;
  
  @media(min-width: ${breakpoints.sm}){
    display: block;
    max-width: 180px;
  }

  @media(min-width: ${breakpoints.md}){
    max-width: 200px;
  }

  @media(min-width: ${breakpoints.lg}){
    max-width: 240px;
  }

  @media(min-width: ${breakpoints.xl}){
    max-width: 350px;
  }

  @media(min-width: 2560px){
    min-width: 450px;
  }
`;

const BulbShadow = styled.img`
  display: none;
  margin: auto;
  position: absolute;
  opacity: 0.7;
  object-fit: cover;

  @media(min-width: ${breakpoints.sm}){
    display: block;
    width: 600px;
    height: 600px;
  }

  @media(min-width: ${breakpoints.md}){
    width: 700px;
    height: 700px;
  }

  @media(min-width: ${breakpoints.lg}){
    width: 800px;
    height: 800px;
  }

  @media(min-width: ${breakpoints.xl}){
    width: 850px;
    height: 850px;
  }

  @media(min-width: ${breakpoints.xxl}){
    width: 950px;
    height: 950px;
  }

  @media(min-width: 2560px){
    width: 1050px;
    height: 1050px;
  }
`;

const BackgroundMobile = styled.img`
  display: block;
  position: absolute;
  top: 60vh;
  min-height: 440px;
  width: 100%;
  z-index: 1;
  left: 0;
  
  @media(min-width: ${breakpoints.sm}) {
    max-height: 440px;
    width: auto;
  }

  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const BackgroundDesktop = styled.img`
  display: none;

  @media(min-width:${breakpoints.md}) {
    display: block;
    position: absolute;
    top: 70vh;
    width: 75vw;
    height: auto;
    max-width: 1226px;
  }
  
  @media(min-width: ${breakpoints.xl}) {
    top: 65vh;
  }

  @media(min-width: ${breakpoints.xxl}) {
    top: 60vh;
  }
`;

const Hero = ({ title, backgrounds }) => {
  const { isLight } = useContext(ThemeContext);
  const { backgroundDesktopDark, backgroundDesktopLight, backgroundMobileDark, backgroundMobileLight } = backgrounds;

  return (
    <HeroContainer>
      <ContentContainer>
        <Row>
          <Col lg={10} lgOffset={1}>
            <Content>
              <ImageBlock>
                  <Fade duration={500}>
                  <ImagePosition>
                  <BulbShadow src={isLight ? ShadowSVG : ShadowDarkSVG} alt="Bulb Shadow"/>
                    <Light src={isLight ? LightSVG : LightDarkSVG} alt={"Light"}/>
                  </ImagePosition>
                  </Fade>
              </ImageBlock>
              <Fade duration={500} delay={200}>
                <Title>{documentToReactComponents(JSON.parse(title.raw))}</Title>
              </Fade>
            </Content>
          </Col>
        </Row>
      </ContentContainer>
      <BackgroundMobile src={isLight ? backgroundMobileLight.file.url : backgroundMobileDark.file.url} alt={isLight ? backgroundMobileLight.title : backgroundMobileDark.title}/>
      <BackgroundDesktop src={isLight ? backgroundDesktopLight.file.url : backgroundDesktopDark.file.url} alt={isLight ? backgroundDesktopLight.title : backgroundDesktopDark.title}/>
    </HeroContainer>
  )
}

export default Hero;
