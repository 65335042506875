import React  from "react";
import styled from "styled-components";
import { Col, Row } from "styled-bootstrap-grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentContainer from "../Core/ContentContainer";
import Title from "../Core/Title";
import Button from "../Core/Button";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Fade from 'react-reveal/Fade';
import Img from "gatsby-image"

const TextImageBlockContainer = styled.div`
  margin: 48px auto 26px;
  position: relative;
  z-index: 10;
  
  @media(min-width: ${breakpoints.md}){
    margin: 136px auto 0;
  }
`;

const ContentBlock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media(min-width: ${breakpoints.md}){
    align-items: flex-end;
    padding-top: 45px;
  }
`;

const Text = styled.div`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.672px;
  ${fonts.RalewayRegular};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  margin-bottom: 26px;
  
  p:first-of-type {
    color: ${colors.red};
    padding-bottom: 10px;
  }

  @media(min-width: ${breakpoints.md}){
    text-align: right;
    width: 380px;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    width: 500px;
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 1.4px;
    padding-top: 20px;
  }

  @media(min-width: ${breakpoints.xl}){
    width: 550px;
    font-size: 38px;
  }
  
  @media(min-width: ${breakpoints.xxl}){
    width: 600px;
    font-size: 41px;
    line-height: 50px;
    letter-spacing: 1.4px;
    margin-bottom: 70px;
  }

  @media(min-width: 1600px){
    width: 650px;
    font-size: 44px;
    line-height: 60px;
    letter-spacing: 1.92px;
  }
`;

const ImageBlock = styled.div`
  position: relative;
  height: 90vw;
  
  @media(min-width: ${breakpoints.md}){
    height: auto;
  }
`;

const Image = styled(Img)`
  display: ${props => props.desktop ? "none" : "block"};
  width: calc(100% + 48px);
  height: auto;
  position: absolute !important;
  top: 16px;
  left: -24px;
  
  @media(min-width: ${breakpoints.md}){
    display: ${props => props.desktop ? "block" : "none"};
    top: -60px;
    width: 110vw;
    left: -24vw;
    height: auto;
  }
  
  @media(min-width: ${breakpoints.lg}){
    top: -80px;
    width: 115vw;
    max-width: 1800px;
  }

  @media(min-width: ${breakpoints.xl}){
    left: -20vw;
  }

  @media(min-width: ${breakpoints.xxl}){
    top: -150px;
    width: 115vw;
    left: -23vw;
  }

  @media(min-width: 1800px){
    left: -25vw !important;
  }
  @media(min-width: 2000px){
    left: -23vw !important;
  }
  @media(min-width: 2200px){
    left: -21vw !important;
  }
  @media(min-width: 2400px){
    left: -20vw !important;
  }
  @media(min-width: 2600px){
    left: -18vw !important;
  }
  @media(min-width: 2800px){
    left: -17vw !important;
  }
  @media(min-width: 3000px){
    left: -16vw !important;
  }
  @media(min-width: 3200px){
    left: -15vw !important;
  }
  @media(min-width: 3400px){
    left: -14vw !important;
  }
`;

const TextImageBlock = ({ introHeader, image, mobileImage, cta, text, index }) => {

  return (
    <TextImageBlockContainer>
      <ContentContainer>
        <Row>
          <Col md={12}>
            {introHeader.header &&
              <Fade duration={500} delay={100}>
                <Title index={index} position={introHeader.position}>
                  {introHeader.header}
                </Title>
              </Fade>
            }
          </Col>
          <Col md={5} lg={6}>
            <Fade duration={500} delay={200}>
              <ImageBlock>
                <Image fluid={image.fluid} alt={image.title} desktop/>
                <Image fluid={mobileImage.fluid} alt={mobileImage.title}/>
              </ImageBlock>
            </Fade>
          </Col>
          <Col md={7} lg={6}>
            <Fade duration={500} delay={300}>
              <ContentBlock>
                {text && <Text>{documentToReactComponents(JSON.parse(text.raw))}</Text>}
                {cta.ctaLabel && <Button to={cta.ctaUrl} isred={true} large={1}>{cta.ctaLabel}</Button>}
              </ContentBlock>
            </Fade>
          </Col>
        </Row>
      </ContentContainer>
    </TextImageBlockContainer>
  )
}

export default TextImageBlock;
